import styled from 'styled-components';
import { device } from '../../utils';

export const Wrapper = styled.section`
  margin: 75px 0;
  padding: 55px 0;
  background: #000;
  color: #fff;
  width: 100%;

  @media ${device.laptop} {
    margin: 80px 0;
    padding: 60px 0;
  }

  .bigtitle {
    margin-top: 0;
  }

  .slide {
    position: relative;
    display: block;
    border-radius: 15px;
    box-sizing: border-box;
    backface-visibility: hidden;
    overflow: hidden;
    transform: translateZ(0);
    z-index: 1;
    transition: transform 0.3s ease-in-out, z-index 2s cubic-bezier(0, 1, 1, 0);

    &:hover {
      transform: scale(1.08) translateZ(0);
      z-index: 100;
    }

    .link {
      position: relative;
      display: block;
    }

    .cover-title {
      position: absolute !important;
      bottom: 0;
      width: 100%;
      height: auto;
      z-index: 20;
      backface-visibility: hidden;
      transform: translateZ(0);
      pointer-events: none;

      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 80%,
        rgba(0, 0, 0, 1) 100%
      );
    }
  }

  .swiper-wrapper {
    /* Used to display overflow on hover */
    padding-top: 20px;
  }

  .swiper-slide {
    width: 40%;
    max-width: 300px;
    margin-right: 8px;
    box-sizing: border-box;
    /* padding-left: 20px;
    box-sizing: border-box;

    &:first-child {
      padding-left: 20px;
    }*/

    &:first-child {
      .slide {
        transform-origin: left center;
      }
    }

    &:last-child {
      .slide {
        transform-origin: right center;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    @media ${device.tablet} {
      margin-right: 16px;
    }
  }

  .swiper-button-disabled {
    display: none !important;
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;

    &:after {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      border-radius: 35px;
      z-index: 1000;
      border: 0;
      min-width: 43px;
      min-height: 43px;
      opacity: 1;
      cursor: pointer;
      color: #000;
      font-size: 18px;
      font-weight: bold;
      transition: box-shadow 0.3s ease-in-out 0s, transform 0.3s ease-in-out 0s;
    }

    @media (hover: hover) {
      display: block;
      &:hover {
        &:after {
          box-shadow: rgb(0 0 0 / 20%) 0px 0px 6px;
          transform: scale(1.15);
        }
      }
    }
  }

  .swiper-button-prev {
    left: 4% !important;
    &:after {
      content: \E824;
    }
  }

  .swiper-button-next {
    right: 4% !important;
    &:after {
      content: \E825;
    }
  }

  .slider-container {
    padding-left: 0;
    padding-right: 0;
    margin-top: -10px;

    &.product {
      .swiper-button-next,
      .swiper-button-prev {
        margin-top: -6%;
      }
    }
  }

  .slider-container.product {
    .react-multiple-carousel__arrow {
      margin-top: -7%;
    }
  }
`;

export const Banner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  padding: 7px 10px;
  pointer-events: none;

  .bg {
    position: absolute;
    inset: 0;
    mix-blend-mode: multiply;
    background: #b10000;
  }

  .text {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;

    .icon {
      margin-right: 10px;
    }
  }

  @media ${device.tablet} {
    font-size: 20px;
  }
`;

export const Video = styled.video`
  position: absolute;
  inset: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  outline: none;
  border: 0;
  object-fit: cover;
  object-position: center;
  opacity: 0;
  transition: opacity 300ms 0s ease-in-out;
  display: none;

  @media (hover: hover) {
    display: block;
  }

  &.show {
    opacity: 1;
    transition: opacity 1s 300ms ease-in-out;
  }
`;
