import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Seo from '../../components/seo';

// import PartnerScreen from '../screens/page/partner';
import usePreviewData from '../../utils/usePreviewData';
import StoriesList from '../../components/stories/List';
import { StoriesBreadcrumb } from '../../components/common/Breadcrumb';

const Wrapper = styled.div`
  background-color: #000;
  width: 100%;
  margin-bottom: 60px;

  .stories-category {
    margin: 60px 0;
    padding: 0;
  }
`;

const PageTemplate = ({ data: rawData }) => {
  const data = usePreviewData(rawData);
  const page = data.page.data;
  const stories = data.stories.nodes;

  return (
    <>
      <Seo
        title={page.title}
        description={page?.seo_description}
      />
      <Wrapper>
        <div className="container">
          <StoriesBreadcrumb />
        </div>
        {page.categories.map(({ category: { document } }) => (
          <StoriesList
            className="stories-category"
            key={document.id}
            title={document.data?.title}
            items={stories.filter(s => s.data?.category?.id === document.prismicId)}
          />
        ))}
      </Wrapper>
    </>
  );
};

export const query = graphql`
  query(
    $cmsLocaleGlob: String!
  ) {
    page: prismicStoriesHome(
      lang: {
        glob: $cmsLocaleGlob
      }
    ) {
      data {
        seo_description
        title
        categories {
          category {
            document {
              ... on PrismicStoriesCategory {
                id
                prismicId
                data {
                  title
                }
              }
            }
          }
        }
      }
    }
    stories: allPrismicStories(
      filter: {
        lang: {
          glob: $cmsLocaleGlob
        }
      }
    ) {
      nodes {
        id
        url
        data {
          title
          category {
            id
          }
          description {
            html
          }
          cover_title {
            url
            dimensions {
              height
              width
            }
            alt
          }
          cover_banner
          cover_background {
            dimensions {
              height
              width
            }
            alt
            url
          }
          video_full {
            embed_url
            html
          }
          video_preview
        }
      }
    }
  }
`;
export default PageTemplate;