import React, { useRef } from 'react';
// import CarouselCore from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, FreeMode } from 'swiper';
// import { useIntersection } from 'react-use';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/free-mode";

import { useWindowSize } from 'react-use';
import VidePlay from '../../icons/VideoPlay.svg';

import cn from 'classnames';

import { Imgix } from '../../utils';
import Title from '../../components/title';
import MagicLink from '../../components/link';
import { Icon } from '../../components/icons';

import {
  Wrapper,
  Banner,
  Video,
} from './List.styles';

const modules = [Navigation, FreeMode];

const imgResponsive = {
  xxs: 4,
  md: 3,
  contained: true,
};

const Slide = ({ className = '', data, url, loading }) => {
  const [hover, setHover] = React.useState(false);
  const videoRef = React.useRef();
  const setIsHover = React.useCallback((e) => {
    setHover(true);

    const videoNode = e.target;
    videoNode.currentTime = 0;
    videoNode.play().catch(exc => {
      console.log('**** catched stories play error', exc);
    });
  }, []);

  const setNotHover = React.useCallback((e) => {
    setHover(false);
    try {
      e.target.pause();
    } catch (exc) {
      console.log('catched pause error', exc);
    };
  }, []);

  // useEffect(() => {
  //   if (hover) {
  //     videoRef.current.play();
  //   } else {
  //     videoRef.current.pause();
  //   }
  //   return () => {
  //   };
  // }, [hover]);

  const videoUrl = data?.video_preview; // "https://player.vimeo.com/external/582022426.sd.mp4?s=b33134aba7da65d627732988d893bde9e2bf3ad1&profile_id=165";
  // const vimeoId = data?.video_preview
  // const previewUrl = `https://player.vimeo.com/video/${vimeoId}?autoplay=1&loop=1&autopause=1&autopause=0`;

  // console.log('is hover', vimeoId, previewUrl);
  return (
    <div
      className={`slide ${className}`}

    >
      <MagicLink href={url} className="link">
        <Imgix
          {...data?.cover_background}
          sizes={imgResponsive}
          loading={loading}
          fadeIn={false}
          key={`force-redraw-${loading}`}
          imgStyle={{
            objectFit: 'cover',
            objectPosition: 'center'
          }}
        />

        {!!data?.cover_title?.url && (
          <Imgix
            {...data?.cover_title}
            sizes={imgResponsive}
            loading={loading}
            className="cover-title"
            fadeIn={false}
            key={`force-title-redraw-${loading}`}
            imgStyle={{
              objectFit: 'contain',
              objectPosition: 'center'
            }}
          />
        )}
        {!!data?.cover_banner && (
          <Banner>
            <div className="bg" />
            <div className="text"><Icon src={VidePlay} width={27} height={27} /> {data.cover_banner}</div>
          </Banner>
        )}
        <Video
          ref={videoRef}
          src={videoUrl}
          onMouseOver={setIsHover}
          onMouseOut={setNotHover}
          className={`${hover ? 'show' : ''}`}
          preload="none"
          muted
        />

      </MagicLink>
    </div>
  );
};

const StoriesList = ({
  className,
  items,
  title,
  titleLevel,
  titleClassName
}) => {
  const { width } = useWindowSize();
  const ref = useRef(null);
  // const isLoaded = true;

  if (!items?.length) {
    return null;
  }

  const spaceLeft = Math.max(20, (width - 1420) / 2);

  // const breakpoints = {
  //   0: {
  //     slidesPerView: 'auto',
  //     slidesOffsetBefore: 20,
  //     slidesOffsetAfter: 20,
  //     spaceBetween: 0
  //   },
  //   1440: {
  //     slidesPerView: 'auto',
  //     slidesOffsetBefore: spaceLeft,
  //     slidesOffsetAfter: 0,
  //     spaceBetween: 16
  //   }
  // };

  return (
    <Wrapper className={className}>
      <div
        className="container"
        ref={ref}
      >
        {title && <Title title={title} as={titleLevel} className={titleClassName} />}
      </div>
      <div
        className={cn('slider-container')}
      >
        <Swiper
          modules={modules}
          slidesPerView="auto"
          // freeModeMomentumBounceRatio={1}
          // freeModeMomentumRatio={.85}
          // freeModeMomentumVelocityRatio={.85}
          watchSlidesProgress
          freeMode
          navigation
          // watchSlidesVisibility
          // breakpoints={breakpoints}
          slidesOffsetBefore={spaceLeft}
          slidesOffsetAfter={20}
          // autoHeight
          preloadImages
        >
          {(items || []).map((item, idx) => {
            return (
              <SwiperSlide
                key={`${idx}-${item.id}`}
              >
                <Slide
                  {...item}
                  loading="lazy"
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </Wrapper>
  );
};

export default StoriesList;
